import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import { media } from '../theme'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      metaTitle
      description
      image {
        asset {
          _ref: _id
          localFile {
            publicURL
          }
        }
      }
      _rawModules
    }
  }
`

const TitleModule = styled(Module).attrs(({ title, image }) => ({
  module: {
    _type: 'titleModule',
    variant: 'transparent',
    title,
    image,
  },
}))`
  height: 65vh;
  max-height: ${rem(900)};
  position: relative;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-size: ${rem(36)};
    ${media('sm')`
      font-size: ${rem(44)};
    `}
  }
`

const MainPage = ({ data: { page }, location: { pathname: path } }) => (
  <Layout>
    <SEO
      title={page.metaTitle || page.title}
      description={page.description}
      image={page.image && page.image.asset.localFile.publicURL}
      path={path}
    />
    <TitleModule title={page.title} image={page.image} />
    {page._rawModules &&
      page._rawModules.map((module, idx) => (
        <Module key={module._key} module={module} />
      ))}
  </Layout>
)

export default MainPage
